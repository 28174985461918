.create-manaul-order {
  background-color: #eee !important;
  padding: 40px !important;
}

.note {
  background-color: #ccc !important;
  padding: 10px;
  margin-top: 10px;
}

#manual-policy-toggle-check {
  margin-top: 10px !important;
}
