.car-list {
  
}

.car-list .row:hover{
  background-color: #ccc;
  cursor: pointer;
}

.brand-name-button {
  font-weight: bold !important;
}

.car-list-accordion .container-fluid:nth-child(even){
  background-color: rgb(242, 242, 242);
}

.car-list-accordion .container-fluid:nth-child(odd){
  background-color: rgb(234, 232, 232);
}

.car-list-accordion .container-fluid .model-row:nth-child(even){
  background-color: rgb(231, 228, 213);
}

.car-list-accordion .container-fluid .model-row:nth-child(odd){
  background-color: rgb(255, 254, 245);
}

.car-list .row {
  padding: 3px;
}

.car-list button {
  margin: 2px;
}