.sidebar {
  min-width: 230px;
  max-width: 230px;
  min-height: 500px;
  max-height: 100vh;
  background: #6b7777;
  margin-left: -280px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}
